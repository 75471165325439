import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d63a018"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headerTop = _resolveComponent("headerTop")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_foot = _resolveComponent("foot")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_headerTop, { onChangeTab: _ctx.selectTab }, null, 8, ["onChangeTab"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_foot)
  ]))
}