
import { defineComponent, ref, nextTick, inject, watch } from 'vue'
import headerTop from '@/components/head/head-top.vue'
import foot from '@/components/foot/foot.vue'
export default defineComponent({
  components: { headerTop, foot },
  setup() {
    const headStatus = ref(true)
    const headTopStatus = ref(true)
    const headTopMsg: any = inject('headTopMsg') || ''
    watch(headTopMsg.value, () => {
      headTop()
    })
    const selectTab = () => {
      headStatus.value = false
      nextTick(() => {
        headStatus.value = true
      })
    }

    const headTop = () => {
      headTopStatus.value = false
      nextTick(() => {
        headTopStatus.value = true
      })
    }
    return {
      selectTab,
      headStatus,
      headTopStatus,
      headTopMsg,
      headTop
    }
  }
})
