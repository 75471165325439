
import { defineComponent, onMounted, ref, watch, nextTick, toRaw } from 'vue'
import router from '@/router'
import localCache from '@/global/cache'
import { outLoginRequest } from '@/api/index'
import { API_BASE_URL } from '@/service/request/config'
import { getArticleTyleList } from '@/api/index'
import { Base64 } from 'js-base64'
export default defineComponent({
  name: 'headTop',
  // changeTab
  emits: ['changeTab'],
  setup(props, context) {
    // getArticleTyleList
    const typeList: any = ref([])
    const typeList4: any = ref([])
    const typeListProxy: any = ref([])
    const moreList: any = ref([])
    const article_categories_id: any = ref()
    const articleRef: any = ref(null)
    const articleRefArr: any = ref([])
    const haedRef: any = ref(null)
    const winScreenWidth = ref<number>(0)
    const showMore: any = ref(false)
    // watch(winScreenWidth, (newData) => {
      
    //   getScreenWidth(newData)
    // })
    watch(articleRefArr, (newData) => {
      let refArr = toRaw(newData)
      let widthArr = []
      widthArr.push(110)
      widthArr.push(110)
      widthArr.push(110)
      widthArr.push(110)
      // widthArr.push(110)
      // widthArr.push(110)
      let widthValue = 360
      for (let i in refArr) {
        let _widthValue =
          newData[i].style.width ||
          newData[i].clientWidth ||
          newData[i].offsetWidth ||
          newData[i].scrollWidth
        widthValue += _widthValue + 30
        widthArr.push(_widthValue + 30)
      }
    })

    // watch(typeListProxy, () => {
    //   getScreenWidth(document.body.clientWidth)
    // })

    const getScreenWidth = (newData: any) => {

      if (newData < 1200) {

        typeList.value = []
        typeList.value = typeList4.value
       
        showMore.value = true;
      } else {
          let value1 = (Math.round(newData/110) - 12);
      
          typeList.value = [];
          typeList.value = JSON.parse(JSON.stringify(typeListProxy.value))
          
          if(value1 < 0 || value1 == 0){
            typeList.value.length = 1
            showMore.value = true;
          } else {
            // console.log("==value1==",value1)
            // alert(value1)
            if(value1>4){
              
              typeList.value.length = value1 - 1
            } else {
              typeList.value.length = value1
            }
            showMore.value = true;
          }
        
      }

      // else if(){
        
      // }
    }

    const getTypeList = async () => {
      
      let dataMsg = await getArticleTyleList({});
      if (dataMsg.errno == 0) {

        typeList.value = dataMsg.data;
        typeListProxy.value = dataMsg.data;
        localCache.setCache('_typeList', JSON.stringify(dataMsg.data))

        for (let i = 0; i < 1; i++) {

          typeList4.value.push(dataMsg.data[i]);
        }
        for (let i = 1; i < dataMsg.data.length; i++) {

          moreList.value.push(dataMsg.data[i]);
        }
        getScreenWidth(document.body.clientWidth)
      }
    }

    getTypeList()

    const out: any = async () => {

      let userLoginLogId = localCache.getCache('userInfoMsg').userLoginLogId
      localCache.deleteCache('userInfoMsg')
      let obj = {
        userLoginLogId: userLoginLogId
      }

      await outLoginRequest(obj)

      router.push({
        path: '/Login'
      })

    }
    const login = async () => {

      router.push({
        path: '/Login'
      })
    }
    const activeIndex: any = ref(0)
    // activeIndex.value = 0
    const addUrl = (url: string, index: number, item: any = null) => {

      localCache.setCache('_sildeIndex', index)
      let params: any = {}
      if (url == '/home/article') {
        ;(window as any).name = item.name
        let str = index.toString()
        params.article_categories_id = str.split('_')[0]
        params.article_categories_id = Base64.encode(
          params.article_categories_id
        )
        ;(window as any).article_categories_id = params.article_categories_id
      }
      activeIndex.value = index
      router.push({
        path: url,
        query: params
      })

      context.emit('changeTab')
      if (params && params.article_categories_id) {
        // setTimeout(() => {
        article_categories_id.value =
          router.currentRoute.value.query.article_categories_id
        // }, 200)
      }
    }
    const username = ref('')
    const userInfoMsg: any = ref({ avatarUrl: '' })
    const getuserInfoMsg = () => {
      username.value = localCache.getCache('user_name')
      if (localCache.getCache('userInfoMsg')) {

        userInfoMsg.value = localCache.getCache('userInfoMsg')
      } else {

        userInfoMsg.value.avatarUrl = ''
        username.value = ''
      }
      // userInfoMsg.value = localCache.getCache('userInfoMsg')
    }
    getuserInfoMsg()
    const goPersonalCenter = () => {

      router.push({
        path: '/home/personalCenter'
      })
    }
    onMounted(() => {
      if (localCache.getCache('_sildeIndex')) {
        //
        activeIndex.value = localCache.getCache('_sildeIndex')
      } else {
        activeIndex.value = 0
      }
      nextTick(async () => {
        setTimeout(() => {
          let _haedRefwidthValue =
            haedRef.value.style.width ||
            haedRef.value.clientWidth ||
            haedRef.value.offsetWidth ||
            haedRef.value.scrollWidth;
            
        }, 200)
    
        
        window.onresize = () => {
          // console.log(document.body.clientWidth);
          // winScreenWidth.value = document.body.clientWidth;
          getScreenWidth(document.body.clientWidth)
        }
      })
    })
    return {
      addUrl,
      username,
      activeIndex,
      out,
      login,
      userInfoMsg,
      API_BASE_URL,
      typeList,
      article_categories_id,
      articleRef,
      articleRefArr,
      haedRef,
      winScreenWidth,
      typeList4,
      typeListProxy,
      moreList,
      showMore,
      getScreenWidth,
      goPersonalCenter
    }
  }
})
